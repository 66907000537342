import React from "react"
import { graphql, Link } from "gatsby"
import windowSize from "react-window-size"

import Stars from "../../components/blog/stars"
import Layout from "../../layouts"
import NewsletterSignup from "../../components/blog/newsletter-signup"
import Header from "../../components/header"
import SEO from "../../components/seo"

import Pagination from "../../components/blog/pagination"
import TagGroup from "../../components/blog/tag-group"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"
import { contentPositionerCss } from "../../components/shared/styles"
import BlogPostPreviewItem from "../../components/blog/blog-post-preview-item"
import Footer from "../../components/footer"

const eyebrowHeadlineCss = theme => ({
  marginBottom: theme.space[9],
  textTransform: "uppercase",
  letterSpacing: theme.letterSpacings.tracked,
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.body,
  color: theme.colors.grey[70],
  borderTop: `1px solid ${theme.colors.grey[30]}`,
  paddingTop: theme.space[5],
})

const topHeaderCss = theme => [
  eyebrowHeadlineCss(theme),
  { borderTop: `none`, paddingTop: `none`, fontSize: theme.fontSizes[2] },
]

const GreyLink = props => (
  <Link
    css={theme => ({
      color: theme.colors.grey[50],
      "&:hover": {
        color: theme.colors.purple[70],
      },
    })}
    {...props}
  />
)

const BreadcrumbHeadline = ({
  currentPage,
  parentTag,
  numPosts,
  tag: { slug, name },
}) => (
  <nav css={topHeaderCss}>
    <GreyLink to="/blog">Blog Home</GreyLink>
    {` / `}
    {parentTag && (
      <>
        <GreyLink to={parentTag.slug}>{parentTag.name}</GreyLink>
        {` / `}
      </>
    )}
    {currentPage > 1 ? (
      <>
        <GreyLink to={`/blog/tags/${slug}`}>{name}</GreyLink>
        {` / ${currentPage}`}
      </>
    ) : (
      `${name} (${numPosts})`
    )}
  </nav>
)

const TagPage = ({
  location,
  pageContext: { keyTags, skip, limit, childTags, numPosts, parentTag },
  data: { wpTag: tag, posts },
}) => {
  const tagName = tag.name.charAt(0).toUpperCase() + tag.name.slice(1)
  const currentPage = parseInt(skip / limit) + 1
  const numPages = Math.ceil(numPosts / limit)

  return (
    <Layout pathname={location.pathname}>
      <SEO
        title={`${tagName} Blog Posts`}
        description={`All posts tagged as ${tagName} on the Gatsby blog.`}
        url={location.href}
      />
      <Header location={location.pathname} />
      <main>
        <SkipNavTarget />
        <Stars />
        <div
          css={theme => [
            contentPositionerCss({ theme }),
            {
              paddingTop: theme.space[7],
            },
          ]}
        >
          {childTags.length ? (
            <div
              css={theme => [
                {
                  paddingTop: theme.space[7],
                  paddingBottom: theme.space[8],
                  display: "flex",
                  flexWrap: "wrap",
                  gap: theme.space[3],
                  [theme.mediaQueries.desktop]: {
                    paddingBottom: theme.space[10],
                  },
                },
              ]}
            >
              <TagGroup tags={childTags} />
            </div>
          ) : null}
          <div>
            <BreadcrumbHeadline
              tag={tag}
              parentTag={parentTag}
              currentPage={currentPage}
              numPosts={numPosts}
            />
          </div>
          <div
            css={theme => [
              {
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: theme.space[10],
                [theme.mediaQueries.tablet]: {
                  gridTemplateColumns: "1fr 1fr",
                },
                [theme.mediaQueries.desktop]: {
                  gridTemplateColumns: "1fr 1fr 1fr",
                },
              },
            ]}
          >
            {currentPage === 1 &&
              posts?.nodes.slice(0, 3).map((post, i) => (
                <div key={i}>
                  <BlogPostPreviewItem post={post} key={post.slug} hasHero />
                </div>
              ))}
            <div
              css={theme => ({
                gridRow: "4",
                [theme.mediaQueries.tablet]: {
                  gridColumn: "span 2",
                  gridRow: "3",
                },
                [theme.mediaQueries.desktop]: {
                  gridColumn: "span 2",
                  gridRow: "2",
                },
              })}
            >
              {currentPage === 1 && <h2 css={eyebrowHeadlineCss}>Read More</h2>}
              {posts?.nodes?.slice(currentPage === 1 ? 3 : 0).map(post => (
                <BlogPostPreviewItem post={post} key={post.slug} hasHero />
              ))}
              <Pagination
                tag={tag.slug}
                numPages={numPages}
                currentPage={currentPage}
              />
            </div>
            <div
              css={theme => ({
                display: "none",
                [theme.mediaQueries.desktop]: {
                  display: "initial",
                  gridRow: 2,
                },
              })}
            >
              <h2 css={eyebrowHeadlineCss}>Categories & Updates</h2>
              <NewsletterSignup />
              <div css={theme => ({ marginBottom: theme.space[4] })} />
              <TagGroup tags={keyTags} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default windowSize(TagPage)

export const pageQuery = graphql`
  query TagPage($id: String!, $ids: [String], $skip: Int!, $limit: Int!) {
    wpTag(id: { eq: $id }) {
      id
      name
      slug
    }
    posts: allWpPost(
      filter: { tags: { nodes: { elemMatch: { id: { in: $ids } } } } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...BlogPostPreviewInformation
      }
    }
  }
`
